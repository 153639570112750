




























import { Prop, Watch, Component, Vue } from "vue-property-decorator";
import NewAddressComponent from "@/features/myaccount/NewAddress.vue";
import AddressCardList from "./AddressCardList.vue";
import axios from "axios";
import {
  MyAccountClient,
  IRegisterBusinessPartnerAddressCommand,
  BusinessPartnerAddressDto2,
  AddressType,
  RegisterBusinessPartnerAddressCommand,
} from "../../base/news-soft-ecommerce-client";

@Component({
  components: { AddressCardList, NewAddressComponent },
})
export default class MyAddress extends Vue {
  @Prop(Array) addresses: Array<BusinessPartnerAddressDto2>;

  private address: BusinessPartnerAddressDto2 = new BusinessPartnerAddressDto2();
  private myAccountClient: MyAccountClient = new MyAccountClient();
  private isShowNewAddressFields: Boolean = false;
  private isLoadingRequest: Boolean = false;

  private cep: number = null;
  private street: string = null;
  private neighborhood: string = null;
  private number: string = null;
  private complement: string = null;
  private city: string = null;
  private state: string = null;
  private referencePoint: string = " ";
  private isDefault: boolean = false;

  private addressType: AddressType = 1;
  private adressOptions: Array<string> = [
    "Residencial",
    "Comercial",
    "Comercial Esposa",
    "Entrega",
    "Entrega de Fatura",
  ];

  created() {
    console.log(this.addresses);
    
  }

  async handleNewAddressForm() {
    
    this.isLoadingRequest = true;

    const addressInfo: IRegisterBusinessPartnerAddressCommand = {
      postalCode: this.cep,
      addressType: this.addressType,
      state: this.state,
      streetName: this.street,
      municipality: this.city,
      neighborhood: this.neighborhood,
      complement: this.complement,
      number: this.number,
      referencePoint: this.referencePoint,
      default: this.isDefault,
    };

    try {
      await this.myAccountClient.address2(
        new RegisterBusinessPartnerAddressCommand(addressInfo)
      );
      this.isLoadingRequest = false;
    } catch (error) {
      console.error(error);
    }
  }

  @Watch("cep")
  async cepChange(cep) {
    const res = await (
      await axios.get(`https://brasilapi.com.br/api/cep/v2/${cep}`)
    ).data;
    this.cep = res.cep;
    this.state = res.state;
    this.neighborhood = res.neighborhood;
    this.city = res.city;
    this.street = res.street;
  }
}
