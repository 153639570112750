










import Vue from "vue";
import Component from "vue-class-component";

import AppHeader from "@/components/AppHeader.vue";
import AppFooter from "@/components/AppFooter.vue";

@Component({
  components: {
    AppHeader,
    AppFooter,
  },
})
export default class App extends Vue {}
