









































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import {
  SearchClient,
  SearchForProductQuery,
  SearchResult as Search,
  CategoryClient,
  CategoryDto2,
  LateralFilterDto,
} from "../../base/news-soft-ecommerce-client";

import SideFilter from "@/components/SideFilter.vue";
import ProductCard from "@/components/ProductCard.vue";

@Component({
  components: { SideFilter, ProductCard },
})
export default class SearchResult extends Vue {
  private searchClient: SearchClient = new SearchClient();
  private searchForProductQuery: SearchForProductQuery = new SearchForProductQuery();
  private categoryClient: CategoryClient = new CategoryClient();
  private categories: CategoryDto2[] = [];
  private categoriesID;
  private search: Search = new Search();
  private lateralFilterDto: LateralFilterDto = new LateralFilterDto();
  private lateralFilter: LateralFilterDto = new LateralFilterDto();
  private keyword;
  private keywordsReturn: string = "";
  private desktop: boolean = true;
  private categoryId;
  private queries = [];
  private charQuery = [];
  private catQuery = [];
  private resultsPerPage = 5;
  private currentPage = 1;
  private productNotFound: boolean = false;

  async created() {
    this.categories = await this.categoryClient.get(null);
    await this.checkSearch(this.keyword);
    console.log(this.search);
    

    var elementDisplay = window.screen.width;

    if (elementDisplay <= 768) {
      this.desktop = false;
    } else {
      this.desktop = true;
    }
  }

  async checkSearch(keyword) {
    this.search = await this.searchClient.post(
      new SearchForProductQuery({
        search: keyword,
        characteristics: [],
        categories: [],
        page: 1,
      })
    );
  }

  async selectCategoryFilter() {
    this.search = await this.searchClient.post(
      new SearchForProductQuery({
        search: `% ${this.keyword} %'`,
        characteristics: [],
        categories: [],
        page: 1,
      })
    );
  }

  async applyProductFilter({ selectedCharacteristic, selectedCategories }) {
    if (selectedCharacteristic.length || selectedCategories.length) {
      try {
        this.search = await this.searchClient.post(
          new SearchForProductQuery({
            search: this.keyword,
            characteristics: [...selectedCharacteristic],
            categories: [...selectedCategories],
            page: 1,
          })
        );
        this.$router.push({
          name: "searchResult",
          query: { busca: this.keyword,
          cat: selectedCategories, char: selectedCharacteristic },
        });
      } catch (error) {
        console.log(error.message);
      }
    } else {
      this.search = await this.searchClient.post(
        new SearchForProductQuery({
          search: this.keyword,
          characteristics: [],
          categories: [],
          page: 1,
        })
      );

      this.$router.push({
        name: "listProducts",
        query: {},
      });

      this.catQuery = null;
      this.charQuery = null;
    }
  }

  async applyFiltersOnCreated(selectedChar, selectedCat) {
    console.log(selectedChar, selectedCat);

    if (selectedCat || selectedCat) {
      try {
        this.search = await this.searchClient.post(
          new SearchForProductQuery({
            search: null,
            characteristics: [Number(...selectedChar)],
            categories: [Number(...selectedCat)],
            page: 1,
          })
        );
        console.log(this.search.products);
      } catch (error) {
        console.log(error.message);
      }
    } else {
      try {
        this.search = await this.searchClient.post(
          new SearchForProductQuery({
            search: null,
            characteristics: [],
            categories: [this.categoryId],
            page: 1,
          })
        );
        console.log(this.search.products);
      } catch (error) {
        console.log(error.message);
      }
    }
  }

  get isSearchEmpty() {
    return this.search ? true : false;
  }

  get productListLength() {
    return this.search.products.length;
  }

  get itemsForList() {
    return this.search.products.slice(
      (this.currentPage - 1) * this.resultsPerPage,
      this.currentPage * this.resultsPerPage
    );
  }

  @Watch("$route", { deep: false, immediate: true })
  async onChangeKeyword(newKeyword, oldKeyword) {
    if (newKeyword != oldKeyword) {
      this.keyword =  this.$route.query.busca;

      await this.checkSearch(this.keyword);
    } else { 
      return;
    }
  }
}
