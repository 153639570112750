















































































































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import HeaderMessage from "@/components/HeaderMessage.vue";
import AppCategoryBar from "@/components/AppCategoryBar.vue";

import {
  HomeDto,
  SharedClient,
  SharedInfoDto,
  ProductDto2,
  SearchClient,
  SearchForProductQuery,
} from "@/base/news-soft-ecommerce-client";

@Component({
  components: {
    HeaderMessage,
    AppCategoryBar,
  },
})
export default class Header extends Vue {
  client: SharedClient = new SharedClient();
  homeDto: HomeDto = new HomeDto();
  sharedClient: SharedClient = new SharedClient();
  sharedInfoDto: SharedInfoDto = new SharedInfoDto();
  searchClient: SearchClient = new SearchClient();
  searchForProductQuery: SearchForProductQuery = new SearchForProductQuery();
  productDto2: ProductDto2[] = [];
  windowWidth: number = window.innerWidth;
  message: string = "Mensagem Comunicado";
  keyword: string = "";
  //keyword = this.$route.query.busca;
  onResize() {
    this.windowWidth = window.innerWidth;
  }

  async buscar(event) {
    this.$router.push({
      path: "/searchResult",
      query: { busca: this.keyword },
    });
  }

  async created() {
    this.sharedInfoDto = await this.sharedClient.get(null);
    this.message = this.sharedInfoDto.communicatedMessage
      ? "this.sharedInfoDto.communicatedMessage "
      : "";
  }

  get isAuthenticated () {
    return this.$store.getters.isAuthenticated;
  } 

  get isCheckoutOrCartRoute() {
    return this.$route.path === "/cart" ||
      this.$route.path === "/payment" ||
      this.$route.path === "/myaccount"
      ? true
      : false;
  }


  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  }

  @Watch("windowWidth", { immediate: true })
  onWindowHeightChanged(val, oldVal) {}

}
