















































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import {
  PaymentPlanClient,
  PaymentPlanDto,
  ShoppingCartClient,
  ShoppingCartItem,
  CheckoutCommand
} from "../../base/news-soft-ecommerce-client";

@Component
export default class Checkout extends Vue {
  paymentPlanClient: PaymentPlanClient = new PaymentPlanClient();

  paymentPlanDto: PaymentPlanDto = new PaymentPlanDto();

  shoppingCartClient: ShoppingCartClient = new ShoppingCartClient();

  checkoutCommand: CheckoutCommand = new CheckoutCommand();

  shoppingCartItems: ShoppingCartItem[] = [];

  private totalOrderShop:number = 0;

  private numOfProduts: number = 0;

  async created() {
    this.shoppingCartItems = await this.shoppingCartClient.get(null);
    this.numOfProduts = this.shoppingCartItems.length;

    this.updateTotal();
    console.log(this.paymentPlanClient.getAll(null))
    console.log(this.checkoutCommand);
    
  }

  updateTotal() {
    const total = this.shoppingCartItems.reduce((acc, val) => val.netTotal + acc, 0);
    this.totalOrderShop = total;
  }
}
