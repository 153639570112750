






















import { Component, Prop, Vue } from "vue-property-decorator";

const ProductCardProps = Vue.extend({
  name: "ProductCard",
});

@Component
export default class ProductCard extends ProductCardProps {
  @Prop({ required: true, type: Object }) readonly itemProduto!: object;
}
