















































































































































































































































































































































































































































































































import { Vue, Component } from "vue-property-decorator";
import {
  AddressType,
  IBusinessPartnerPhoneDto,
  BusinessPartnerAddressDto,
  IBusinessPartnerDto,
  MyAccountClient,
  IBusinessPartnerEmailDto2,
  BusinessPartnerEmailDto2,
  IBusinessPartnerPhisicalDto,
  BusinessPartnerPhisicalDto,
  PhoneType,
  EmailType,
  Sex,
  EducationLevel,
  CivilStatus,
  RegisterBusinessPartnerCommand,
  IEditBusinessPartnerCommand,
  EditBusinessPartnerCommand,
  IBusinessPartnerPhoneDto2,
  IRegisterBusinessPartnerCommand,
  IEditBusinessPartnerPhone,
  EditBusinessPartnerPhone,
  BusinessPartnerPhoneDto2,
  IBusinessPartnerAddressDto,
} from "../../base/news-soft-ecommerce-client";
import NewAddressComponent from "@/features/myaccount/NewAddress.vue";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import {
  required,
  digits,
  alpha_spaces,
  numeric,
  min,
  max,
  email,
} from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "Esse campo é necessário",
});
extend("digits", digits);
extend("alphaSpaces", alpha_spaces);
extend("numeric", numeric);
extend("min", min);
extend("max", max);
extend("email", {
  ...email,
  message: "O email é inválido",
});
extend("cpf", (cpf: string) => {
  var Soma;
  var Resto;
  Soma = 0;
  if (cpf == "00000000000") return "O CPF não é válido";

  for (let i = 1; i <= 9; i++)
    Soma = Soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;

  if (Resto == 10 || Resto == 11) Resto = 0;
  if (Resto != parseInt(cpf.substring(9, 10))) return "O CPF não é válido";

  Soma = 0;
  for (let i = 1; i <= 10; i++)
    Soma = Soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
  Resto = (Soma * 10) % 11;

  if (Resto == 10 || Resto == 11) Resto = 0;
  if (Resto != parseInt(cpf.substring(10, 11))) return "O CPF não é válido";
  return true;
});

@Component({
  components: { NewAddressComponent, ValidationProvider, ValidationObserver },
})
export default class InfoComponent extends Vue {
  private myAccountClient = new MyAccountClient();
  private isNewClient: boolean = false;
  private isLoadingRequest: boolean = false;
  private successMessage: string = null;
  private errorMessage: string = null;

  private clientInfo: IBusinessPartnerDto = null;
  private fullName: string = null;
  private city: string = null;
  private state: string = null;
  private civilState: CivilStatus = null;
  private educationLevel: EducationLevel = null;
  private email: string = null;
  private cpf: string = null;
  private gender: Sex = 0;
  private dateOfBirth: string = null;
  private phone: string = null;
  private companyName: string = null;
  private emailtype: EmailType = 0;
  private phoneArray: Array<object> = [];
  private newPhoneArray: Array<IBusinessPartnerPhoneDto2> = [];
  private phoneType: PhoneType = null;
  private emailtypes: Array<string> = [
    "Pessoal",
    "Corporativo",
    "Recebimento de recibo",
  ];
  private phoneTypes: Array<string> = [
    "Residencial",
    "Comercial",
    "Celular",
    "Residencial Esposa",
    "Comercial Esposa",
    "Celular Esposa",
  ];
  private genderTypes: Array<string> = [
    "Masculino",
    "Feminino",
    "Prefiro não informar",
  ];
  private educationTypes: Array<string> = [
    "Não informar",
    "Ensino Médio",
    "Ensino Médio Incompleto",
    "Ensino Superior Completo",
    "Ensino Superior em Andamento",
    "Pós-Graduação em andamento",
    "Pós-Graduação completo",
    "Mestrado/Doutorado",
    "Analfabeto",
  ];
  private civilstatusTypes: Array<string> = [
    "Não informado",
    "Casado",
    "Solteiro",
    "Viúvo",
    "União Estável",
    "Separado",
    "Divorciado",
  ];

  private newCep: number = null;
  private newStreet: string = null;
  private newNeighborhood: string = null;
  private newNumber: string = null;
  private newComplement: string = "";
  private newCity: string = null;
  private newState: string = null;
  private newReferencePoint: string = "";
  private addressType: AddressType = 1;

  private address: IBusinessPartnerAddressDto = {
    addressType: this.addressType,
    postalCode: this.newCep,
    state: this.newState,
    municipality: this.city,
    neighborhood: this.newNeighborhood,
    streetName: this.newStreet,
    complement: this.newComplement,
    number: this.newNumber,
    referencePoint: this.newReferencePoint,
  };

  async created() {
    console.log(this.$store.getters.userProfile);

    try {
      this.clientInfo = await this.myAccountClient.businessPartner(null);
      if (this.clientInfo?.documents.length) {
        this.isNewClient = false;
        this.phoneArray = this.clientInfo.phones.map((phone) => {
          const phoneObject: IEditBusinessPartnerPhone = {
            id: phone.phonetId,
            number: phone.phone,
            type: phone.phoneType,
            areaCode: phone.ddd,
            extension: "",
          };
          return phoneObject;
        });
        this.gender = this.clientInfo.sex;
        const formatedDate = this.formatDate(this.clientInfo.birthDate);
        this.dateOfBirth = formatedDate;
        this.companyName = this.clientInfo.companyName;
      } else {
        this.isNewClient = true;
      }
    } catch (error) {
      this.isNewClient = true;
    }
  }

  handleAddOtherPhone() {
    this.errorMessage = null;
    if (!this.phone || !this.phoneType) {
      this.errorMessage =
        "Favor informar o telefone e o tipo antes de adicionar um novo telefone";
      console.log(this.errorMessage);

      return;
    }

    const newPhone: IBusinessPartnerPhoneDto2 = {
      type: this.phoneType,
      areaCode: this.phone
        .split("")
        .slice(1, 3)
        .join(""),
      number: this.phone.substring(4).replace("-", ""),
      extension: " ",
    };

    this.newPhoneArray.push(newPhone);
    console.log(this.newPhoneArray);

    this.phone = null;
    this.phoneType = null;
  }

  handleDeletePhone(index: number) {
    this.phoneArray.splice(index, 1);
  }

  async handleNewBusinessPartner() {
    this.isLoadingRequest = true;

    const email: IBusinessPartnerEmailDto2 = {
      emailType: this.emailtype,
      emailAddres: this.email,
    };

    const phisicalRequest: IBusinessPartnerPhisicalDto = {
      sex: this.gender,
      birthCity: this.city,
      birthState: this.state,
      civilStatus: this.civilState,
      educationLevel: this.educationLevel,
    };

    const newPhones: Array<IBusinessPartnerPhoneDto2> = this.newPhoneArray.map(
      (phone) => {
        const p: IBusinessPartnerPhoneDto2 = {
          type: phone.type,
          areaCode: phone.areaCode,
          number: phone.number,
          extension: phone.extension,
        };
        console.log(p);
        
        return p;
      }
    );

    console.log(newPhones);
    
    const newClient: IRegisterBusinessPartnerCommand = {
      name: this.fullName,
      birthDate: new Date(this.dateOfBirth),
      cpf: this.cpf,
      email: new BusinessPartnerEmailDto2(email),
      phisical: new BusinessPartnerPhisicalDto(phisicalRequest),
      address: [new BusinessPartnerAddressDto(this.address)],
      phones: [new BusinessPartnerPhoneDto2(...newPhones)],
    };

    try {
      await this.myAccountClient.businessPartner2(
        new RegisterBusinessPartnerCommand(newClient)
      );
      this.isLoadingRequest = false;
      this.successMessage = "Dados cadastrados com sucesso!";
      setTimeout(() => {
        this.successMessage = null;
      });
    } catch (error) {
      console.log(error.message);
    }
  }

  async handleEditBusinessPartner() {
    this.isLoadingRequest = true;

    const editPhone: Array<IEditBusinessPartnerPhone> = this.phoneArray.map(
      (phone: IEditBusinessPartnerPhone) => {
        return {
          id: phone.id,
          type: phone.type,
          areaCode: phone.areaCode,
          number: phone.number,
          extension: " ",
        };
      }
    );

    const editedClient: IEditBusinessPartnerCommand = {
      companyName: this.companyName,
      phones: [new EditBusinessPartnerPhone(...editPhone)],
      birthDate: new Date(this.dateOfBirth),
      sex: this.gender,
    };

    try {
      await this.myAccountClient.businessPartner3(
        new EditBusinessPartnerCommand(editedClient)
      );
      this.isLoadingRequest = false;
      this.successMessage = "Mudanças enviadas!";
      setTimeout(() => {
        this.successMessage = null;
      }, 2000);
    } catch (error) {
      console.error(error.message);
    }
  }

  formatDate(d: Date) {
    let date = d;
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - offset * 60 * 1000);
    return date.toISOString().split("T")[0];
  }
}
