import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import apiAuthorizationRoutes from '@/features/authentication/routes'
import product from "@/features/product/ProductPage.vue";
import category from "@/features/category/category.vue";
import MyAccount from "@/features/myaccount/myaccount.vue"
import payment from "@/features/payment/Checkout.vue";
import cart from "@/features/cart/ShoppingCart.vue";
import admin from "@/features/admin/admin.vue";
import ListProductsPage from "@/features/category/ProductList.vue";
import SearchResult from "@/features/search/searchResult.vue";
import Page404 from "@/components/Page404.vue";
import { authorizeGuard } from "@/store/modules/authorization/authorize-guard";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "index",
    component: () => import('@/features/home/Home.vue').then(m => m.default),
  },
  {
    path: "/product/:productId",
    name: "product",
    component: product,
    props: true,
  },
  {
    path: "/listProducts/:categoryId",
    name: "listProducts",
    component: ListProductsPage,
    props: true,
  },
  {
    path: "/myaccount",
    name: "myaccount",
    component: MyAccount,
    meta: { requiresAuth: true },
  },
  {
    path: "/payment",
    name: "payment",
    component: payment,
  },
  {
    path: "/cart",
    name: "ShoppingCart",
    component: cart,
  },
  {
    path: "/admin",
    name: "admin",
    component: admin,
  },
  {
    path: "/searchResult",
    name: "searchResult",
    component: SearchResult,
  },
  {
    path: "*",
    component: Page404,
  },
  ...apiAuthorizationRoutes,
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

authorizeGuard(router)

export default router;
