











































































































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import RegisteredAdressCard from "./RegisteredAdressCard.vue";
import {
  MyAccountClient,
  BusinessPartnerAddressDto2,
  AddressType,
  IEditBusinessPartnerAddressCommand,
  EditBusinessPartnerAddressCommand,
} from "../../base/news-soft-ecommerce-client";
import MyAccount from "./MyAccount.vue";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import {
  required,
  digits,
  alpha_spaces,
  numeric,
  min,
  max,
} from "vee-validate/dist/rules";
extend("required", required);
extend("digits", digits);
extend("alphaSpaces", alpha_spaces);
extend("numeric", numeric);
extend("min", min);
extend("max", max);

@Component({
  components: { RegisteredAdressCard, ValidationProvider, ValidationObserver },
})
export default class AddressCardList extends Vue {
  @Prop(Array) addresses: Array<BusinessPartnerAddressDto2>;
  private isLoadingRequest: boolean = false;
  private myAccountClient: MyAccountClient = new MyAccountClient();
  private isEditAdress: Boolean = false;
  private adressId: number = null;
  private addressType: AddressType = null;
  private postalCode: number = null;
  private state: string = null;
  private municipality: string = null;
  private neighborhood: string = null;
  private address: string = null;
  private complement: string = null;
  private number: string = null;
  private referencePoint: string = null;
  private isDefault: boolean = null;
  private showSuccessEditAddress = false;
  private adressOptions: Array<string> = [
    "Residencial",
    "Comercial",
    "Comercial Esposa",
    "Entrega",
    "Entrega de Fatura",
  ];

  handleEditAddress(info) {
    if (this.isEditAdress) {
      this.closeEditForm();
    } else {
      this.openEditForm();
    }
    console.log(info);
    this.adressId = info.businessPartnerAddressId;
    this.addressType = info.addressType;
    this.postalCode = info.cep;
    this.state = info.country;
    this.municipality = info.city;
    this.neighborhood = info.neighborhood;
    this.address = info.address;
    this.number = info.number;
    this.complement = info.complement;
    this.referencePoint = info.referencePoint;
    this.isDefault = info.default;
  }

  async handleSubmitEditAddress() {
    this.isLoadingRequest = true;
    
    const editedForm: IEditBusinessPartnerAddressCommand = {
      addressId: this.adressId,
      addressType: this.addressType,
      postalCode: this.postalCode,
      municipality: this.municipality,
      neighborhood: this.neighborhood,
      state: this.state,
      number: this.number,
      default: this.isDefault,
      referencePoint: this.referencePoint,
      complement: this.complement,
      streetName: this.address,
    };

    try {
      await this.myAccountClient.address(
        new EditBusinessPartnerAddressCommand(editedForm)
      );
      const filter = (await this.myAccountClient.addressAll(null)).filter(
        (itens) => {
          return itens.businessPartnerAddressId === this.adressId
            ? itens.businessPartnerAddressId
            : "";
        }
      );
      this.showSuccessEditAddress = true;
      this.isLoadingRequest = false;
      setTimeout(() => {
        this.showSuccessEditAddress = false
      }, 1500)
    } catch (error) {
      console.error(error);
    }
  }

  closeEditForm() {
    this.isEditAdress = false;
  }

  openEditForm() {
    this.isEditAdress = true;
  }
}
