














































































































































































































































































































































































































































































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class Admin extends Vue {
  //        $(document).ready(function () {
  //    // Color Picker Tool Js
  //      const dynamicInputs = document.querySelectorAll('input.input-color-picker');
  //      const handleThemeUpdate = (cssVars) => {
  //      const root = document.querySelector(':root');
  //      const keys = Object.keys(cssVars);
  //      keys.forEach(key => {
  //        root.style.setProperty(key, cssVars[key]);
  //      });
  //    }
  //    dynamicInputs.forEach((item) => {
  //      item.addEventListener('input', (e) => {
  //        const cssPropName = `--cor-${e.target.getAttribute('data-id')}`;
  //        console.log(cssPropName)
  //        handleThemeUpdate({
  //          [cssPropName]: e.target.value
  //        });
  //      });
  //    });
  //});
  //    function showPreview(event) {
  //            if (event.target.files.length > 0) {
  //                var src = URL.createObjectURL(event.target.files[0]);
  //                var preview = document.getElementById("banner-desktop-file-preview");
  //                preview.src = src;
  //                preview.style.display = "block";
  //            }
  //        }
  //        function showPreviewMobile(event) {
  //            if (event.target.files.length > 0) {
  //                var src = URL.createObjectURL(event.target.files[0]);
  //                var preview = document.getElementById("banner-mobile-file-preview");
  //                preview.src = src;
  //                preview.style.display = "block";
  //            }
  //        }
}
