






import Vue from "vue";
import { Prop, Component } from "vue-property-decorator";


@Component
export default class HeaderMessage extends Vue {
  @Prop({required: true, type: String}) readonly message!: string
  get messageString(): string {
    return this.message;
  }
}
