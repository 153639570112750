























































































































































































































import { Component, Vue } from "vue-property-decorator";
import { extend, ValidationProvider, ValidationObserver } from "vee-validate";

import { required } from "vee-validate/dist/rules";
import {
  ProductClient,
  Product,
  ProductDto,
  ShoppingCartClient,
  ShoppingCartItem,
  AddItemCommand,
} from "../../base/news-soft-ecommerce-client";

extend("required", {
  ...required,
  message: "{_field_} é um campo necessário",
});

@Component({ components: { ValidationProvider, ValidationObserver } })
export default class ProductInfos extends Vue {
  private productClient: ProductClient = new ProductClient();
  private product: Product = new Product();
  private shoppingCartClient: ShoppingCartClient = new ShoppingCartClient();
  private shoppingCartItems: ShoppingCartItem[] = [];
  private isDisabled: boolean = false;
  private showDisabledTooltip = false;
  private showSuccessAddToCart = false;
  private selectedSize = "";
  private selectedColor = "";
  private imgLink = ""

  async created() {
    this.product.productDto = new ProductDto();
    this.product = await this.productClient.get(
      Number.parseInt(this.$route.params.productId)
    );

    this.imgLink = this.product?.productImageDto[0]?.link;

    console.log(this.isTooltipEnabled)
  }

  addToCart(id) {
    this.isDisabled = true;
    console.log(this.isDisabled);
    try {
      this.shoppingCartClient.addItem(
        new AddItemCommand({
          productId: id,
          quantity: 1,
        })
      );
      this.showSuccessAddToCart = true;
      setTimeout(() => {
        this.isDisabled = false;
        this.showSuccessAddToCart = false;
      }, 2000);
    } catch (error) {
      console.log(error);
    }
  }

  buyProduct(id) {
    try {
      this.shoppingCartClient.addItem(
        new AddItemCommand({
          productId: id,
          quantity: 1,
        })
      );
      this.$router.push({ name: "payment" });
    } catch (e) {}
  }

  changeImage(a) {
    var elementImage = <HTMLImageElement>document.getElementById("img");
    elementImage.src = a;
  }

  get isTooltipEnabled() {
    if(this.colors && this.sizes) {
      return this.selectedSize && this.selectedColor ? true : false;
    } else {
      return true
    }
  }

  get colors() {
    return this.product.colors.length > 0;
  }

  get sizes() {
    return this.product.sizes.length > 0;
  }
}
