













































import Vue from "vue";
import { Component } from "vue-property-decorator";

import {
  SharedClient,
  SharedInfoDto,
} from "../base/news-soft-ecommerce-client";

@Component({
  name: "AppFooter",
})
export default class AppFooter extends Vue {
  sharedClient: SharedClient = new SharedClient();

  sharedInfoDto: SharedInfoDto = new SharedInfoDto();

  get currentYear() {
    return new Date().getFullYear();
  }

  get fullAdress() {
    return `${this.sharedInfoDto.company.address}, ${this.sharedInfoDto.company.number} - 
      ${this.sharedInfoDto.company.neighborhood} - ${this.sharedInfoDto.company.city} - SC,
      88704-260`;
  }

  get companyPhone() {
    return this.sharedInfoDto.company.phone;
  }

  get linkLogo() {
    return this.sharedInfoDto.company.linkLogo;
  }


  links = [];

  async created() {
    this.sharedInfoDto = await this.sharedClient.get();
    
    this.links = this.sharedInfoDto.socialNetworks;
  }
}
