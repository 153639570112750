


















































































































import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class SideFilter extends Vue {
  @Prop({ type: Object }) filters!: Object;
  @Prop({ type: Array }) charQuery!: Array<string>;
  @Prop({ type: Array }) catQuery!: Array<string>;

  private selected = null;
  private minPrice: number = null;
  private maxPrice: number = null;
  private categories = [];
  private characteristics = [];
  private showFilters: boolean = false;
  private windowWidth: number = window.innerWidth;

  private priceList: Object = [
    {
      text: "Até R$50,00",
      value: 50,
    },
    {
      text: "R$50,00 a R$100,00",
      value: 100,
    },
    {
      text: "Mais de R$100,00 ",
      value: 99999,
    },
  ];

  created() {
    window.addEventListener("resize", this.getWindowWidth);
    this.categories = [...this.catQuery];
    this.characteristics = [...this.charQuery];
  }

  handleFilter() {
    const selectedFilters = {
      selectedCategories: this.categories,
      selectedCharacteristic: this.characteristics,
    };

    this.$emit("apply-filter", selectedFilters);
  }

  destroyed() {
    window.removeEventListener("resize", this.getWindowWidth);
  }

  handleShowFilters() {
    this.showFilters = !this.showFilters;
  }

  getWindowWidth(e) {
    console.log(e.target.innerWidth);
    this.windowWidth = e.target.innerWidth;
  }

  get isMobile() {
    return this.windowWidth <= 768;
  }
}
