export default (title: string, numChar: number) => {
  let formattedTitle: string;

  if (!numChar) {
    formattedTitle = title.substring(0, 100) + "...";
  } else {
    formattedTitle = title.substring(0, numChar) + "...";
  }
  return formattedTitle;
};
