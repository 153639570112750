











































import { Component, Watch, Vue } from "vue-property-decorator";

import {
  CategoryClient,
  CategoryDto2,
  GroupDto,
  ListCategoryQuery,
  SearchClient,
  SearchForProductQuery,
  SearchResult,
  HomeDto,
} from "../../base/news-soft-ecommerce-client";
import Carrousel from "@/components/Carrousel.vue";
import SideFilter from "@/components/SideFilter.vue";
import ProductCard from "@/components/ProductCard.vue";
import _ from "lodash";

@Component({
  components: { Carrousel, SideFilter, ProductCard },
})
export default class ListProductsPage extends Vue {
  private groupDto: GroupDto;
  private homeDto: HomeDto = new HomeDto();
  private categoryClient: CategoryClient = new CategoryClient();
  private listCategoryQuery: ListCategoryQuery = new ListCategoryQuery();
  private categories: CategoryDto2[] = [];
  private searchClient: SearchClient = new SearchClient();
  private searchForProductQuery: SearchForProductQuery = new SearchForProductQuery();
  private categoriesFilter: number = 0;
  private desktop: boolean = true;
  private categoryId;
  private queries = [];
  private charQuery = [];
  private catQuery = [];
  private resultsPerPage = 5;
  private currentPage = 1;
  private windowWidth: number = window.innerWidth;
  private productNotFound: boolean = false;
  private searchResult: SearchResult = null;
  private keyword: string = null;
  private imgList: Array<object> = [
    {
      imgSrc: "https://picsum.photos/1096/324/?image=52",
      imgCaption: "Teste",
    },
    {
      imgSrc: "https://picsum.photos/1096/324/?image=53",
      imgText: "Teste teste teste",
    },
  ];

  async created() {
    window.addEventListener("resize", this.getWindowWidth);
    this.categoryId = this.$route.params.categoryId;
    if (!_.isEmpty(this.$route.query)) {
      if (_.isObject(this.$route.query.cat)) {
        this.catQuery.push(...this.catQuery, ...this.$route.query.cat);
      } else {
        this.catQuery.push(this.$route.query.cat);
      }

      if (_.isObject(this.$route.query.char)) {
        this.charQuery.push(...this.catQuery, ...this.$route.query.char);
      } else {
        this.charQuery.push(this.$route.query.char);
      }

      this.applyFiltersOnCreated(this.charQuery, this.catQuery);
      // this.applyFiltersOnCreated(this.charQuery, this.catQuery);
    } else {
      this.selectCategory(this.categoryId);
    }
  }

  async applyProductFilter({ selectedCharacteristic, selectedCategories }) {
    if (selectedCharacteristic.length || selectedCategories.length) {
      try {
        this.searchResult = await this.searchClient.post(
          new SearchForProductQuery({
            search: null,
            characteristics: [...selectedCharacteristic],
            categories: [...selectedCategories],
            page: 1,
          })
        );
        this.$router.push({
          name: "listProducts",
          query: { cat: selectedCategories, char: selectedCharacteristic },
        });
      } catch (error) {
        console.log(error.message);
      }
    } else {
      this.searchResult = await this.searchClient.post(
        new SearchForProductQuery({
          search: null,
          characteristics: [],
          categories: [this.categoryId],
          page: 1,
        })
      );

      this.$router.push({
        name: "listProducts",
        query: {},
      });

      this.catQuery = null;
      this.charQuery = null;
    }
  }

  async selectCategory(categoryID) {
    this.searchResult = await this.searchClient.post(
      new SearchForProductQuery({
        search: null,
        characteristics: [],
        categories: [categoryID],
        page: 1,
      })
    );
  }

  async applyFiltersOnCreated(selectedChar, selectedCat) {
    console.log(selectedChar, selectedCat);

    if (selectedCat || selectedCat) {
      try {
        this.searchResult = await this.searchClient.post(
          new SearchForProductQuery({
            search: null,
            characteristics: [Number(...selectedChar)],
            categories: [Number(...selectedCat)],
            page: 1,
          })
        );
        console.log(this.searchResult.products);
      } catch (error) {
        console.log(error.message);
      }
    } else {
      try {
        this.searchResult = await this.searchClient.post(
          new SearchForProductQuery({
            search: null,
            characteristics: [],
            categories: [this.categoryId],
            page: 1,
          })
        );
        console.log(this.searchResult.products);
      } catch (error) {
        console.log(error.message);
      }
    }
  }

  destroyed() {
    window.removeEventListener("resize", this.getWindowWidth);
  }

  get productListLength() {
    return this.searchResult.products.length
  }

  get itemsForList() {
    return this.searchResult.products.slice(
      (this.currentPage - 1) * this.resultsPerPage,
      this.currentPage * this.resultsPerPage
    )
  }

  getWindowWidth(e) {
    console.log(e.target.innerWidth);
    this.windowWidth = e.target.innerWidth;
  }

  get isMobile() {
    return this.windowWidth <= 768;
  }

  @Watch("$route")
  onChangeRoute(oldVal, newVal) {
    if (!oldVal.query.cat || !oldVal.query.char) {
      this.selectCategory(oldVal.params.categoryId);
    }
  }

}
