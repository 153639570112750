import VueRouter from 'vue-router';
import { Dictionary } from 'vue-router/types/router';
import { AuthorizationModule } from '.';
import { AuthrorizationPaths, QueryParameterNames } from './constants';

export function authorizeGuard(router: VueRouter): void {

    // Whenever a route changes,
    // this function is called before rendering the target path
    router.beforeEach(async (to, from, next) => {

        if (!to.matched.some(record => record.meta.requiresAuth)) {
            // Route does not require authorization
            next();
        } else if (AuthorizationModule.isAuthenticated) {
            // User is authenticated
            next();
        } else {
            // Route requires authorization and
            // User is not authenticated then
            // Redirect to login page
            const query: Dictionary<string> = {};

            query[QueryParameterNames.ReturnUrl] = to.fullPath;

            next({
                path: AuthrorizationPaths.Login,
                query
            });
        }

    });
}