<template>
  <div class="container">
    <h1>Página não encontrada</h1>
    <p>A página que você está procurando não foi encontrada, revise o endereço e tente novamente</p>
    <router-link to="/" >Voltar para página inicial</router-link>
  </div>
</template>

<script>
import { Vue, Component } from "vue-property-decorator";

@Component
export default class Page404 extends Vue {}
</script>
