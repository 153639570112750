











































import { OrderDto } from "@/base/news-soft-ecommerce-client";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
  name: "OrderItem",
})
export default class OrderItem extends Vue {
  @Prop(Array) readonly orders!: Array<object>;
  private isShowProducts: boolean = false;
  private orderMetaInfos = null;
  private orderProductInfos = null;

  async created() {
    this.orderMetaInfos = this.orders.pop();
    this.orderProductInfos = this.orders;
  }

  handleShowProducts() {
    this.isShowProducts = !this.isShowProducts;
  }
}
