
































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import {
  ShoppingCartClient,
  ShoppingCartItem,
  RemoveItemCommand,
  SetItemCommand,
  CheckoutCommand,
} from "../../base/news-soft-ecommerce-client";

import ShoppingItem from "./ShoppingItem.vue";

@Component({
  components: { ShoppingItem },
})
export default class ShoppingCart extends Vue {
  shoppingCartClient: ShoppingCartClient = new ShoppingCartClient();
  shoppingCartItems: ShoppingCartItem[] = [];
  totalOrderShop: number = 0;
  isEmptyCart: boolean = false;

  async created() {
    this.shoppingCartItems = await this.shoppingCartClient.get(null);
    this.isEmptyCart = !this.shoppingCartItems.length ? true : false;
    this.updateTotalOrder();
    console.log(this.shoppingCartItems);
  }

  updateTotalOrder() {
    this.totalOrderShop = this.shoppingCartItems.reduce(
      (prevPrice, currentPrice) => prevPrice + currentPrice.netTotal,
      0
    );
  }

  removeItem(index, id) {
    console.log(id);
    try {
      this.shoppingCartClient.delete(
        new RemoveItemCommand({
          productId: id,
        })
      );
      console.log(this.shoppingCartClient);
      this.shoppingCartItems.splice(index, 1);
      this.updateTotalOrder();
    } catch (e) {
      console.log(e);
    }
  }

  async remove(item) {
    let found = this.shoppingCartItems.find(
      (product) => product.productId == item.productId
    );

    if (found) {
      this.shoppingCartClient.delete(
        new RemoveItemCommand({
          productId: found.productId,
        })
      );
      this.shoppingCartItems = await this.shoppingCartClient.get(null);
      this.updateTotalOrder();
    }
  }

  incrementItemQntity(item) {
    let found = this.shoppingCartItems.find(
      (product) => product.productId == item.productId
    );

    if (found) {
      this.updateCart(found, 1);
    }
  }

  decrementItemQntity(item) {
    let found = this.shoppingCartItems.find(
      (product) => product.productId == item.productId
    );

    if (found) {
      if (item.quantity > 0) {
        this.updateCart(found, -1);
      }
    }
  }

  async updateCart(item, quantity) {
    let q = quantity + item.quantity;

    let data = {
      quantity: q,
      id: item.productId,
    };

    switch (q) {
      case 0:
        this.remove(item);
        break;
      default:
        await this.shoppingCartClient.put(
          new SetItemCommand({
            productId: data.id,
            quantity: data.quantity,
          })
        );
    }

    this.shoppingCartItems = await this.shoppingCartClient.get(null);
    if (!this.shoppingCartItems.length) {
      this.isEmptyCart = true;
    }
    this.updateTotalOrder();
  }

  async updateProductQntity(amount, item) {
    console.log(amount);
    console.log(item);

    let data = {
      quantity: amount,
      id: item.productId,
    };

    switch (amount) {
      case 0:
        this.remove(item);
        break;
      default:
        await this.shoppingCartClient.put(
          new SetItemCommand({
            productId: data.id,
            quantity: data.quantity,
          })
        );
    }

    this.shoppingCartItems = await this.shoppingCartClient.get(null);
    if (!this.shoppingCartItems.length) {
      this.isEmptyCart = true;
    }
    this.updateTotalOrder();
  }
}
