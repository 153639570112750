



























import Vue from "vue";

import { Component, Prop } from "vue-property-decorator";
@Component({
  name: "Carrousel",
})
export default class Carrousel extends Vue {
  @Prop({ required: true, type: Array }) readonly imgList!: Array<object>;

  slide = 0;
  sliding = null;

  onSlideStart(slide) {
    this.sliding = true;
  }

  onSlideEnd(slide) {
    this.sliding = false;
  }
}
