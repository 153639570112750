













































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { ShoppingCartItem } from "../../base/news-soft-ecommerce-client";

@Component({
  name: "ShoppingItem",
})
export default class ShoppingItem extends Vue {
  @Prop(ShoppingCartItem) readonly shoppingItem!: ShoppingCartItem;
  private amount: number;
  private isLoading: boolean = null;

  created() {
    this.amount = this.shoppingItem.quantity;
  }

  decrementItem() {
    this.amount = this.amount - 1;
    this.isLoading = true;
    
    setTimeout(() => {
      this.$emit("decrement-item", this.amount);
      this.isLoading = false;
    }, 700);
  }

  incrementItem() {
    this.amount = this.amount + 1;
    this.isLoading = true;
    setTimeout(() => {
      this.$emit("increment-item", this.amount);
      this.isLoading = false;
    }, 700);
  }
}
