








import { MyAccountClient, OrderItemsDto } from "../../base/news-soft-ecommerce-client";
import { Vue, Component, Prop } from "vue-property-decorator";
import OrderItem from "./OrderItem.vue";

@Component({
  components: { OrderItem },
})
export default class MyOrders extends Vue {
  @Prop(Array) readonly orders:Array<object>;
  private isShowProducts: boolean = false;

  handleShowProducts() {
    this.isShowProducts = !this.isShowProducts;
  }
}
