
























































import { Component, Vue } from "vue-property-decorator";
import {
  MyAccountClient,
  OrderItemsDto,
  OrderDto,
  ListBusinesPartnerAddressQuery,
  BusinessPartnerDto,
  BusinessPartnerAddressDto2,
  EditBusinessPartnerCommand,
  EditBusinessPartnerAddressCommand,
  DeleteBusinessPartnerAddressCommand,
  RegisterBusinessPartnerCommand,
  RegisterBusinessPartnerAddressCommand,
  IRegisterBusinessPartnerAddressCommand,
} from "../../base/news-soft-ecommerce-client";

import MyOrders from "./MyOrdersComponent.vue";
import MyAddress from "./MyAddressComponent.vue";
import MyInfo from "./MyInfoComponent.vue";

@Component({
  components: { MyOrders, MyAddress, MyInfo },
})
export default class MyAccount extends Vue {
  private myAccountClient: MyAccountClient = new MyAccountClient();
  private orderItemsDto: OrderItemsDto[][] = [];
  private businessPartnerDto: BusinessPartnerDto = new BusinessPartnerDto();
  private orderDto: OrderDto[] = [];
  private businessPartnerAddressDto: BusinessPartnerAddressDto2[] = [];
  private editBusinessPartnerCommand: EditBusinessPartnerCommand = new EditBusinessPartnerCommand();
  private registerBusinessPartner = new RegisterBusinessPartnerCommand();

  private adresses: Array<BusinessPartnerAddressDto2> = null;

  private currentTab: string = "MyOrders";
  private orderInfo = [];

  async created() {
    console.log(this.myAccountClient.businessPartner(null));
    
    try {
      await this.myAccountClient.businessPartner(null);
    } catch (error) {
      this.currentTab = "MyInfo";
    }

    
    
    
    this.orderDto = await this.myAccountClient.orders(null);
    this.adresses = await this.myAccountClient.addressAll(null);
    console.log(this.adresses)

    this.orderDto.forEach(async (order) => {
      let itens = await this.myAccountClient.orderItems(order.orderId);
      this.orderInfo.push([...itens, order]);
    });
  }

  handleChangeTab(tab) {
    this.currentTab = tab;
  }
}
