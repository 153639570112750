










































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import {
  CategoryClient,
  CategoryDto2,
  SharedClient,
  SharedInfoDto,
  SearchClient,
  SearchForProductQuery,
} from "../base/news-soft-ecommerce-client";

@Component
export default class AppCategoryBar extends Vue {
  @Prop(Number) readonly windowWidth!: Number;
  categoryClient: CategoryClient = new CategoryClient();
  allCategories: CategoryDto2[] = [];
  highlightCategories: CategoryDto2[] = [];
  sharedClient: SharedClient = new SharedClient();
  sharedInfoDto: SharedInfoDto = new SharedInfoDto();
  private isDropDownOpen = false;

  //________________Novos______________________//
  searchClient: SearchClient = new SearchClient();

  searchForProductQuery: SearchForProductQuery = new SearchForProductQuery();

  async created() {
    this.allCategories = await this.categoryClient.get(null);
    this.highlightCategories = (await this.sharedClient.get(null)).categories;
  }

  handleOpenDropdown() {
    this.isDropDownOpen = !this.isDropDownOpen;
  }

  handleCloseDropdown() {
    this.isDropDownOpen = false;
  }

  get isMobile() {
    return this.windowWidth <= 760;
  }
}
