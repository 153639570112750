
















import { BusinessPartnerAddressDto2 } from "@/base/news-soft-ecommerce-client";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class RegisteredAdressCard extends Vue {
  @Prop(BusinessPartnerAddressDto2)
  private registeredAddressInfo: BusinessPartnerAddressDto2;

  handleEditAddress() {
    this.$emit('handle-edit-address', this.registeredAddressInfo)
  }

  get isDefault() {
    return this.registeredAddressInfo.default ? "padrão" : "opcional";
  }

  get fullAdress() {
    return `${this.registeredAddressInfo.address}, ${this.registeredAddressInfo.number} - 
    ${this.registeredAddressInfo.neighborhood}, ${this.registeredAddressInfo.city} - 
    ${this.registeredAddressInfo.country}, ${this.registeredAddressInfo.cep}`;
  }
}
