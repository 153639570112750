import './router/componentHooks'
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import vClickOutside from 'v-click-outside'
import ProgressBar from './plugins/progress-bar.js';
import 'nprogress/nprogress.css';

ProgressBar(router);

import "./assets/css/styles.css";
import "./assets/tailwind.css";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import {
  BootstrapVue,
  CarouselPlugin, 
  PaginationPlugin,
  NavbarPlugin,
  IconsPlugin,
  VBPopoverPlugin,
  FormPlugin,
  ModalPlugin,
} from "bootstrap-vue";

import Component from 'vue-class-component'

import DateFilter from "./filters/date";
import NumberFilter from "./filters/currency";
import FormatTitle from './filters/formatProductTitle'
import CapitalizeTitle from './filters/capitalizeTitle'

import VueMask from 'v-mask';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate',
]);

Vue.use(BootstrapVue);
Vue.use(PaginationPlugin);
Vue.use(NavbarPlugin);
Vue.use(VBPopoverPlugin);
Vue.use(FormPlugin);
Vue.use(ModalPlugin);
Vue.use(IconsPlugin);
Vue.use(vClickOutside);

Vue.use(VueMask);

Vue.filter("date", DateFilter);
Vue.filter("currency", NumberFilter);
Vue.filter("formatTitle", FormatTitle)
Vue.filter("capitalizeTitle", CapitalizeTitle)

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
