








































































































































































































































































































































































































































import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import {
  MyAccountClient,
  IRegisterBusinessPartnerAddressCommand,
  BusinessPartnerAddressDto2,
  AddressType,
  RegisterBusinessPartnerAddressCommand,
  IBusinessPartnerAddressDto,
} from "../../base/news-soft-ecommerce-client";
import axios from "axios";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import {
  required,
  digits,
  alpha_spaces,
  numeric,
  min,
  max,
} from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "Esse campo é necessário",
});
extend("digits", digits);
extend("alphaSpaces", alpha_spaces);
extend("numeric", numeric);
extend("min", min);
extend("max", max);

@Component({ components: { ValidationObserver, ValidationProvider } })
export default class NewAddressComponent extends Vue {
  @Prop(String) readonly componentType!: String;
  @Prop(Object) addressProps: IBusinessPartnerAddressDto;

  private address: BusinessPartnerAddressDto2 = new BusinessPartnerAddressDto2();
  private myAccountClient: MyAccountClient = new MyAccountClient();
  private isShowNewAddressFields: Boolean = false;
  private isLoadingRequest: Boolean = false;
  private addedAddressMessage: String = null;
  private showSuccessNewAddress: Boolean = false;
  private cep: number = null;
  private street: string = null;
  private neighborhood: string = null;
  private number: string = null;
  private complement: string = "";
  private city: string = null;
  private state: string = null;
  private referencePoint: string = "";
  private isDefault: boolean = false;
  private addressList: Array<IBusinessPartnerAddressDto> = [];

  private addressType: AddressType = 1;
  private adressOptions: Array<string> = [
    "Residencial",
    "Comercial",
    "Comercial Esposa",
    "Entrega",
    "Entrega de Fatura",
  ];

  handleAddAddress() {
    const address: IBusinessPartnerAddressDto = {
      addressType: this.addressType,
      postalCode: this.cep,
      state: this.state,
      municipality: this.city,
      neighborhood: this.neighborhood,
      streetName: this.street,
      complement: this.complement,
      number: this.number,
      referencePoint: this.referencePoint,
    };

    this.$emit("add-new-address", address);
  }

  async handleNewAddressForm() {
    this.isLoadingRequest = true;

    const addressInfo: IRegisterBusinessPartnerAddressCommand = {
      postalCode: this.cep,
      addressType: this.addressType,
      state: this.state,
      streetName: this.street,
      municipality: this.city,
      neighborhood: this.neighborhood,
      complement: this.complement,
      number: this.number,
      referencePoint: this.referencePoint,
      default: this.isDefault,
    };

    try {
      await this.myAccountClient.address2(
        new RegisterBusinessPartnerAddressCommand(addressInfo)
      );
      this.showSuccessNewAddress = true;
      this.isLoadingRequest = false;
      setTimeout(() => {
        this.showSuccessNewAddress = false;
      }, 1500);
    } catch (error) {
      console.error(error);
    }
  }

  closeAddNewAdressForm() {
    this.$emit("close-new-adress-form");
  }

  @Watch("cep")
  async cepChange(cep) {
    const res = await (
      await axios.get(`https://brasilapi.com.br/api/cep/v2/${cep}`)
    ).data;
    this.cep = res.cep;
    this.state = res.state;
    this.neighborhood = res.neighborhood;
    this.city = res.city;
    this.street = res.street;
  }
  
}
